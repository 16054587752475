<template>
  <publicImport :importTypeList="importTypeList"></publicImport>
</template>

<script>
import { aliasb039bd6e4486415fba0a6b42f8cd95d1 } from '@/customFolder/customVueAlias.js';

import publicImport from "@/components/common/publicImport";

export default {
  name: "importTask",
  data() {
    return {
      importTypeList: [
        {value: 'productImport', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000726')},
        {value: 'inventoryImport', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000760')},
        {value: 'productGoodsSuggestPriceImport', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1000792')},
        {value: 'productGoodsStatusImport', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1005853')},
        {value: 'importSupplierStockOrder', name: aliasb039bd6e4486415fba0a6b42f8cd95d1.t('key1004627')},
      ]
    };
  },
  components: {
    publicImport
  }
};
</script>
<style scoped>

</style>
